import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>
                    Màster Class de Casos Pràctics en directe (Específic Policia Municipal de
                    Sabadell 2024)
                </Title>
                <Text>
                    Específic per la policia Municipal de Sabadell 2024.
                    <br />
                    <br />
                    A més vídeo, introducció d'1 hora i 30 minuts.
                    <br />
                    <br />
                    Us presentem el millor curs per preparar els casos pràctics de la prova pràctica
                    corresponent a la convocatòria de 2024.
                    <br />
                    <br />
                    La prova pràctica s' ha convertit en imprescindible, ja que és una prova
                    eliminatòria i es valorarà de 0 a 10 punts. Per poder continuar amb el procés de
                    selecció caldrà obtenir una puntuació mínima de 5 punts
                    <br />
                    <br />
                    El dia de la connexió farem un repàs al marc legislatiu per tal de tenir clar
                    els conceptes a l'hora de redactar el cas pràctic.
                    <br />
                    <br />
                    Ens centrarem en els aspectes més importants, així com redactar correctament i
                    sobretot repassarem els casos pràctics oficials de les darreres oposicions amb
                    correccions en vers al tribunal qualificador.
                    <br />
                    <br />
                    La connexió en directe serà el diumenge 21 d'abril de 8.30 a 12.30 h a través de
                    ZOOM i quedarà gravada fins al dia de l'examen.
                    <br />
                    <br />
                    Per sols 39,99 euros
                    <br />
                    <br />
                    T'esperem!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/bJwa-3BO0pk"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
